import logo from './logo.svg';
import '../../App.css';
import './AItest.css';
import { request, LocalStorage } from '../../App';
import Header from '../Header/Header';
import { useReactMediaRecorder } from "react-media-recorder";

import btn_back from '../../images/btn_back.svg';
import btn_forward from '../../images/btn_forward.svg';
import btn_highlight from '../../images/btn_highlight.svg';
import btn_play from '../../images/btn_play.svg';
import btn_record from '../../images/btn_record.svg';
import btn_retry from '../../images/btn_retry.svg';
import btn_stop from '../../images/btn_stop.svg';
import btn_fin from '../../images/btn_fin.png';
import { useState, useRef, useEffect } from 'react';

import ranger_original from '../../images/ranger.svg';

function AItest() {
	const [token, setToken] = LocalStorage('token', '');
	const [weekLogId, setWeekLogId] = useState(0);

	const [coverFillColor, setCoverFillColor] = useState('none');
	const consoleCover = useRef(null);
	const [countdownNum, setCountdownNum] = useState(3);
	const countdownNumRef = useRef(null);
	const spinnerRef = useRef(null);
	const [spinnerText, setSpinnerText] = useState('');
	const pagnationRef = useRef(null);
	const mainTextRef = useRef(null);
	const [mainText, setMainText] = useState('');
	const [subText, setSubText] = useState('');
	const btnBoxRef = useRef(null);

	const btnArray = [];
	const [btnBox, setBtnBox] = useState(btnArray);

	const pagnationArray = [];
	const [pagnationBox, setPagnationBox] = useState(pagnationArray);

	const mimeType = 'audio/mp4';
	const [music, setMusic] = useState(null);
	const musicRef = useRef(null);
	const firstBtnRef = useRef(null);
	const playBtnRef = useRef(null);
	const lastBtnRef = useRef(null);
	const recordingAudio = useRef(null);
	const recodingBackground = useRef(null);

	const questionArray = useRef(null);

	const [rangerTextState, setRangerTextState] = useState("어서와~ 친구들!");
	const bunnyTellerRef = useRef(null);

	const btnArrStore = useRef(null);

	const [trimmedAudioURL, setTrimmedAudioURL] = useState(null);


	const customOnStart = () => {
		console.log("start");
		setTrimmedAudioURL(null);

		recordingAudio.current.volume = 0;
		recordingAudio.current.muted = 1;
		
		// 통신부나 이런거 저장해야함
	}
	const customOnStop = async (url, blob) => {
		console.log("stop");
		console.log(url, blob);
		let tmpBox = btnArrStore.current;
		let text = tmpBox[1].text;
		tmpBox[1] = {
			img_source: btn_retry,
			func: recordingControl,
			text: text,
			ref: playBtnRef,
		}
		setBtnBox(tmpBox);

		lastBtnRef.current.style.opacity = 1;
		lastBtnRef.current.style.pointerEvents = 'auto';
		recordingAudio.current.style.display = 'block';

		recordingAudio.current.volume = 1;
		recordingAudio.current.muted = 0;
		trimAudio(blob);
		
		if(document.getElementsByClassName("console-page-selected")[0]){
			const pageNum = document.getElementsByClassName("console-page-selected")[0].innerText-1;

			const formData = new FormData();
			formData.append('audio', blob);

			console.log(pageNum);
			console.log(questionArray.current[pageNum].ql_id);
			formData.append('ql_id',questionArray.current[pageNum].ql_id);
			const response = await fetch(`https://ranger.purpleacademy.co.kr/api_v2/upload_from_recorder`, {
				method: 'post',
				body: formData,
			});
			const results = await response.json();
			console.log(results);
			unMuteAudio();
		}
		
	}

	const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, muteAudio, unMuteAudio, previewAudioStream } = useReactMediaRecorder({ video: false, audio: true, blobPropertyBag:{type: mimeType} , onStart: customOnStart, onStop: customOnStop, askPermissionOnMount:true });

	// 오디오의 앞부분 2초를 자르는 함수
	const trimAudio = async (audioBlob) => {
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const arrayBuffer = await audioBlob.arrayBuffer(); // Blob을 ArrayBuffer로 변환
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

		// 2초 이후의 오디오를 자르기
		const duration = audioBuffer.duration;
		const startOffset = 2; // 앞부분 2초 제거
		const trimmedDuration = duration - startOffset;

		// OfflineAudioContext를 사용하여 새 버퍼에 자른 오디오를 기록
		const offlineContext = new OfflineAudioContext(
		audioBuffer.numberOfChannels,
		trimmedDuration * audioBuffer.sampleRate,
		audioBuffer.sampleRate
		);

		const source = offlineContext.createBufferSource();
		source.buffer = audioBuffer;
		source.connect(offlineContext.destination);
		source.start(0, startOffset); // 2초 이후부터 시작

		const trimmedBuffer = await offlineContext.startRendering();

		// 자른 오디오 데이터를 다시 Blob으로 변환
		const trimmedBlob = await bufferToBlob(trimmedBuffer, audioBuffer.sampleRate);
		const audioUrl = URL.createObjectURL(trimmedBlob);
		setTrimmedAudioURL(audioUrl);
	};

	// AudioBuffer를 Blob으로 변환하는 함수
	const bufferToBlob = (audioBuffer, sampleRate) => {
		const numOfChannels = audioBuffer.numberOfChannels;
		const length = audioBuffer.length * numOfChannels * 2;
		const buffer = new ArrayBuffer(length);
		const view = new DataView(buffer);

		let offset = 0;
		for (let i = 0; i < audioBuffer.length; i++) {
		for (let channel = 0; channel < numOfChannels; channel++) {
			const sample = audioBuffer.getChannelData(channel)[i] * 0x7fff;
			view.setInt16(offset, sample, true);
			offset += 2;
		}
		}

		const wavHeader = createWavHeader(audioBuffer, sampleRate, numOfChannels, length);
		return new Blob([wavHeader, view], { type: 'audio/wav' });
	};

	// WAV 헤더를 만드는 함수
	const createWavHeader = (audioBuffer, sampleRate, numOfChannels, dataLength) => {
		const buffer = new ArrayBuffer(44);
		const view = new DataView(buffer);

		/* ChunkID "RIFF" */
		writeString(view, 0, 'RIFF');
		view.setUint32(4, 36 + dataLength, true);
		writeString(view, 8, 'WAVE');

		/* Subchunk1ID "fmt " */
		writeString(view, 12, 'fmt ');
		view.setUint32(16, 16, true); // Subchunk1Size for PCM
		view.setUint16(20, 1, true); // Audio format (1 = PCM)
		view.setUint16(22, numOfChannels, true); // Number of channels
		view.setUint32(24, sampleRate, true); // Sample rate
		view.setUint32(28, sampleRate * numOfChannels * 2, true); // Byte rate
		view.setUint16(32, numOfChannels * 2, true); // Block align
		view.setUint16(34, 16, true); // Bits per sample

		/* Subchunk2ID "data" */
		writeString(view, 36, 'data');
		view.setUint32(40, dataLength, true);

		return buffer;
	};

	// 문자열을 DataView에 쓰는 함수
	const writeString = (view, offset, string) => {
		for (let i = 0; i < string.length; i++) {
		view.setUint8(offset + i, string.charCodeAt(i));
		}
	};


	const recordingControl = (page) =>{// 녹음 시작
		
		//오디오 사라지게 설정 필요
		clearBlobUrl();
		muteAudio();
		recordingAudio.current.style.display = 'none';

		consolePlay();
		setTimeout(()=>{

			let tmpBox = btnArrStore.current;
			let text = tmpBox[1].text;
			tmpBox[1] = {
				img_source: btn_stop,
				func: stopRecordingDelay,
				text: text,
				ref: playBtnRef,
			}
			setBtnBox(tmpBox);

			startRecording();
		},1000)
	}

	function stopRecordingDelay(){
		let tmpBox = btnArrStore.current;
		let text = tmpBox[1].text;
		tmpBox[1] = {
			img_source: btn_retry,
			func: recordingControl,
			text: text,
			ref: playBtnRef,
		}
		setBtnBox(tmpBox);
		stopRecording();
		if (previewAudioStream) {
			const tracks = previewAudioStream.getTracks();
			tracks.forEach(track => track.stop()); // 스트림 종료
		}
	}

	function consolePlay() {
		return new Promise((resolve) => {
			if (previewAudioStream) {
				const tracks = previewAudioStream.getTracks();
				tracks.forEach(track => track.stop()); // 스트림 종료
			}
			recordingAudio.current.volume = 0;
			
			setCoverFillColor('#000000B2');
			consoleCover.current.style.zIndex = 3;
			countdownNumRef.current.style.display = 'block';
			setCountdownNum(3);
			setRangerTextState('3!');
			setTimeout(() => {
				setCountdownNum(2);
				setRangerTextState('2!');
				setTimeout(() => {
					setCountdownNum(1);
					setRangerTextState('1!');
					setTimeout(() => {
						countdownNumRef.current.style.display = 'none';
						consoleCover.current.style.zIndex = 0;
						setCoverFillColor('none');
						resolve(1);
					}, 1000);
				}, 1000);
			}, 1000);
		});
	}

	function spinnerCtrl(bool, text = '데이터를 불러오는 중입니다.') {
		return new Promise((resolve) => {
			if (bool) {
				setSpinnerText(text);
				spinnerRef.current.style.display = 'block';
				resolve(1);
			} else {
				setSpinnerText('');
				spinnerRef.current.style.display = 'none';
				resolve(0);
			}
		});
	}

	function testPagectrl(bool) {
		return new Promise((resolve) => {
			if (bool) {
				pagnationRef.current.style.display = 'block';
				mainTextRef.current.style.display = 'block';
				resolve(1);
			} else {
				pagnationRef.current.style.display = 'none';
				setBtnBox(btnArray);
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_fin,
						text: '권한허용',
						ref: firstBtnRef,
					},
				]);
				setBtnBox((current) => [
					...current,
					{
						func: recordingControl,
						img_source: btn_record,
						text: '테스트',
						ref: playBtnRef,
						variable: -1,
					},
				]);
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_forward,
						func: renderPage,
						text: '시작하기',
						ref: lastBtnRef,
						variable: 0,
					},
				]);

				const arr = [{
					img_source: btn_fin,
					text: '권한허용',
					ref: firstBtnRef,
				},{
					func: startRecording,
					img_source: btn_record,
					text: '테스트',
					ref: playBtnRef,
				},{
					img_source: btn_forward,
					func: renderPage,
					text: '시작하기',
					ref: lastBtnRef,
					variable: 0,
				},]

				btnArrStore.current = arr
				
				resolve(0);
			}
		});
	}

	async function finishTest(){
		recordingAudio.current.style.display = 'none';
		window.location.replace("comments")
	}

	async function submitWeekLog(){
		recordingAudio.current.style.display = 'none';
		pagnationRef.current.style.display = 'none';
		mainTextRef.current.style.display = 'none';
		btnBoxRef.current.style.display = 'none';
		await spinnerCtrl(1);
		const submitResult = await request(`update_week_log`, {
			week_log_id: questionArray.current[0].week_log_id,
		});
		await spinnerCtrl(0);
		mainTextRef.current.style.display = 'block';
		setMainText("제출이 완료되었습니다.")
		setSubText('홈페이지 계획표 인증을 잊지마세요!');
		setRangerTextState("다음주에 또 만나자!")

		btnBoxRef.current.style.display = 'flex';
		console.log(submitResult);
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_forward,
				func: finishTest,
				text: '코멘트 확인하기',
				ref: firstBtnRef,
			},
		]);
	}

	function renderPage(pageNum) {
		// checkMicrophonePermission()
		// console.log(pageNum);
		let tmpBox = [0,1,2];

		if (pageNum === -1) {
			
			testPagectrl(0);
			
			setPagnationBox(pagnationArray);
			pagnationRef.current.style.display = 'none';
			setMainText('마이크 사용 권한을 허용하고, 음성을 테스트해주세요.');
			setRangerTextState('마이크 테스트~ 아~');
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
			recordingAudio.current.style.display = 'none';
			return;
		}
		if(pageNum===0){
			setRangerTextState('자~ 준비!');
		}

		firstBtnRef.current.style.opacity = 1;
		firstBtnRef.current.style.pointerEvents = 'auto';

		const questionState = questionArray.current;
		const thisPage = questionState[pageNum];

		recordingAudio.current.style.display = 'none';
		

		setBtnBox(btnArray);
		setPagnationBox(pagnationArray);
		for (let i = 0; i < questionState.length; i++) {
			if (i === pageNum) {
				setPagnationBox((current) => [
					...current,
					{
						class_name: 'console-page console-page-selected',
						value: pageNum + 1,
					},
				]);
				
			} else {
				setPagnationBox((current) => [
					...current,
					{ class_name: 'console-page', value: '' },
				]);
			}
		}
		pagnationRef.current.style.display = 'flex';

		setMainText(thisPage.question);

		setMusic(
			'https://ranger.purpleacademy.co.kr/api/sound/' +
				thisPage.sound_file
		);

		// type , status, 끝 페이지 인지
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				func: renderPage,
				text: '뒤로가기',
				variable: pageNum - 1,
				ref: firstBtnRef,
			},
		]);

		tmpBox[0] = {
			img_source: btn_back,
			func: renderPage,
			text: '뒤로가기',
			variable: pageNum - 1,
			ref: firstBtnRef,
		}

		console.log(thisPage);
		if(isNaN(thisPage.record_file)){
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
			// mediaBlobUrl = 'https://ranger.purpleacademy.co.kr/api_v2/convert/'+thisPage.record_file;
			// recordingAudio.current.style.display = 'block';
			recordingAudio.current.pause()
			if (thisPage.type === 0) {
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_retry,
						func: recordingControl,
						variable: pageNum,
						text: '다시하기',
						ref: playBtnRef,
					},
				]);

				tmpBox[1] = {
					img_source: btn_retry,
					func: recordingControl,
					variable: pageNum,
					text: '다시하기',
					ref: playBtnRef,
				}
			} else {
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_retry,
						func: recordingControl,
						variable: pageNum,
						text: '다시하기',
						ref: playBtnRef,
					},
				]);

				tmpBox[1] = {
					img_source: btn_retry,
					func: recordingControl,
					variable: pageNum,
					text: '다시하기',
					ref: playBtnRef,
				}
			}
		}else{
			if (thisPage.type === 0) {
				playBtnRef.current.src = btn_play;
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_play,
						func: recordingControl,
						variable: pageNum,
						text: '시작하기',
						ref: playBtnRef,
					},
				]);

				tmpBox[1] = {
					img_source: btn_play,
					func: recordingControl,
					variable: pageNum,
					text: '시작하기',
					ref: playBtnRef,
				}
			} else {
				playBtnRef.current.src = btn_record;
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_record,
						func: recordingControl,
						variable: pageNum,
						text: '시작하기',
						ref: playBtnRef,
					},
				]);

				tmpBox[1] = {
					img_source: btn_play,
					func: recordingControl,
					variable: pageNum,
					text: '시작하기',
					ref: playBtnRef,
				}
			}

			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
		}

		if (pageNum === questionState.length - 1) {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_fin,
					func: submitWeekLog,
					text: '제출하기',
					variable: pageNum + 1,
					ref: lastBtnRef,
				},
			]);
			tmpBox[2] = {
				img_source: btn_fin,
				func: submitWeekLog,
				text: '제출하기',
				variable: pageNum + 1,
				ref: lastBtnRef,
			}
		} else {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: renderPage,
					text: '다음으로',
					variable: pageNum + 1,
					ref: lastBtnRef,
				},
			]);
			tmpBox[2] = {
				img_source: btn_forward,
				func: renderPage,
				text: '다음으로',
				variable: pageNum + 1,
				ref: lastBtnRef,
			}
		}
		btnArrStore.current = tmpBox;
	}

	const weekcheck = async () => {
		await testPagectrl(0);

		await spinnerCtrl(1);

		const result = await request('weekcheck', { token: token });
		setWeekLogId(result.week_log_id);
		console.log(result);
		if (result.status === 0) {
			setRangerTextState("다음주에 또 만나자!");
			setMainText('솔루션을 이미 진행했어요!');
			setSubText('홈페이지 계획표 인증을 잊지마세요!');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'flex';
			setBtnBox(btnArray);
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: finishTest,
					text: '코멘트 확인하기',
					ref: firstBtnRef,
				},
			]);
		} else if (result.status === -1) {
			setMainText('솔루션 대상이 아닙니다.');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'none';
		} else if( result.status ===2){
			setRangerTextState('수요일에 만나자!');
			setMainText('솔루션 가능기간이 아닙니다. (월, 화)');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'flex';
			setBtnBox(btnArray);
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: finishTest,
					text: '코멘트 확인하기',
					ref: firstBtnRef,
				},
			]);
		}else {
			// checkMicrophonePermission();
			await spinnerCtrl(0);
			setMainText('마이크 사용 권한을 허용하고, 음성을 테스트해주세요.');
			mainTextRef.current.style.display = 'block';
			let get_test_questions = await request('get_test_question', {
				token: token,
				week_log_id: result.week_log_id,
			});
			console.log(get_test_questions);
			if (get_test_questions.status) {
				get_test_questions = await request('get_test_question', {
					token: token,
					week_log_id: result.week_log_id,
				});
			}
			questionArray.current = get_test_questions;

			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
		}
	};
	useEffect(() => {
		weekcheck();
	}, []);

	return (
		<div className="App">
			<div className="full_wrapper aitest-scroll">
				<Header />
				<main className="aitest-main-body">
					<div className="music-container">
						<audio src={music} ref={musicRef} volume="1"></audio>
					</div>
					<div className="console-wrapper">
						<svg
							className="icon-close"
							onClick={() => {
								window.location.replace('main');
							}}
							xmlns="http://www.w3.org/2000/svg"
							width="68"
							height="68"
							viewBox="0 0 68 68"
							fill="none"
						>
							<circle cx="36" cy="36" r="32" fill="#313131" />
							<circle cx="32" cy="32" r="32" fill="#565656" />
							<rect
								x="41.9048"
								y="16"
								width="8.61994"
								height="36.6347"
								rx="4.30997"
								transform="rotate(45 41.9048 16)"
								fill="white"
							/>
							<rect
								x="16"
								y="22.0951"
								width="8.61994"
								height="36.6347"
								rx="4.30997"
								transform="rotate(-45 16 22.0951)"
								fill="white"
							/>
						</svg>
						<div className="spinner-wrapper" ref={spinnerRef}>
							<div id="spinner"></div>
							<div>{spinnerText}</div>
						</div>
						<div className="countdown-number" ref={countdownNumRef}>
							{countdownNum}
						</div>
						<svg
							className="console-cover"
							ref={consoleCover}
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="100%"
							viewBox="0 0 1168 672"
							preserveAspectRatio="none"
							fill="none"
						>
							<path
								d="M27.7129 157.525C39.9856 75.0529 110.796 14 194.177 14H973.823C1057.2 14 1128.01 75.0529 1140.29 157.525C1157.9 275.857 1157.9 396.143 1140.29 514.475C1128.01 596.947 1057.2 658 973.823 658H194.177C110.796 658 39.9856 596.947 27.7129 514.475C10.104 396.143 10.104 275.857 27.7129 157.525Z"
								fill={coverFillColor}
								stroke="#E6D7E8"
								strokeWidth="28"
							/>
						</svg>
						<div className="console-pagnation" ref={pagnationRef}>
							{pagnationBox.map((element, index) => {
								return (
									<div className={element.class_name} key={index}>
										{element.value}
									</div>
								);
							})}
						</div>
						<div className="console-msg-box" ref={mainTextRef}>
							{mainText}
							<br/>
							<span className='aitest-sub-text'>{subText}</span>
						</div>

						<img
							className="recording-background"
							src={btn_highlight}
							ref={recodingBackground}
							alt=""
						/>

						<div className="console-btn-box" ref={btnBoxRef}>
							{btnBox.map((element, index) => {
								return (
									<div key={index} className="btn-set">
										<div className="btn-img-wrapper">
											<img
												src={element.img_source}
												alt=""
												ref={element.ref}
												onClick={() => element.func(element.variable)}
											/>
										</div>
										<div className="btn-name">{element.text}</div>
									</div>
								);
							})}
							<div className="audio-container"><audio style={{"display":"none"}} src={trimmedAudioURL} controls autoPlay ref={recordingAudio} /> </div>
						</div>

						
						<svg
							className="console-box"
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="100%"
							preserveAspectRatio="none"
							viewBox="0 0 1168 672"
							fill="none"
						>
							<path
								d="M27.7129 157.525C39.9856 75.0529 110.796 14 194.177 14H973.823C1057.2 14 1128.01 75.0529 1140.29 157.525C1157.9 275.857 1157.9 396.143 1140.29 514.475C1128.01 596.947 1057.2 658 973.823 658H194.177C110.796 658 39.9856 596.947 27.7129 514.475C10.104 396.143 10.104 275.857 27.7129 157.525Z"
								fill="white"
								stroke="#E6D7E8"
								strokeWidth="0"
							/>
						</svg>
						<div className="detail-ranger-wrapper">
							<div className="comment-ranger-text">{rangerTextState}</div>
							<img
								className="comment-readingranger"
								alt=""
								height="220px"
								ref={bunnyTellerRef}
								src={ranger_original}
							/>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}

export default AItest;
